<template>
 <b-container fluid>
      <b-row class="my-1 mt-2">
        <b-col sm="3" class="ml-n2 text-lg-right">
         <label for="input-default"> Intermediary Name :</label>
        </b-col>
        <b-col sm="9" class="ml-2">
          <advanced-search class="mb-4"
            v-model="model"
            :options="options"
            :selectMode = true
            placeholder='Select an Intermediary'>
             <!-- @select="onValueSelect($event)"> -->
          </advanced-search>
        </b-col>
      </b-row>
      <b-row class="my-1 mt-2">
        <b-col sm="3" class="ml-n2 text-lg-right">
         <label for="input-default">Choose File :</label>
        </b-col>
        <b-col sm="9" class="ml-2">
          <b-form-file v-model="file2" plain></b-form-file>
        </b-col>
      </b-row>
      <b-button type="submit" class="m-2" variant="primary">Submit</b-button>
      <b-button type="reset" class="m-2" variant="danger">Reset</b-button>
  </b-container>              
</template>

<script>
import AdvancedSearch from 'vue2-advanced-search'
export default {
    name: "UploadFile",
    components: { AdvancedSearch },
    // methods:{
    //   onValueSelect(value){
    //   }
    // },
    data() {
      return {
        file1: null,
        file2: null,
        model: "",
        options: [
            { label: 'label1', value: 'value1' },
            { label: 'label2', value: 'value2' }
         ]
      }
    },
    async mounted() {
      let res = await this.$store.dispatch("fetchJobs");
      let newArray = [];
       res.forEach(currentValue => {
         newArray.push({ value: currentValue.id, label: currentValue.name})
       });
       this.options = newArray;
    }
  }
</script>
<style>
.advanced-search-input {
    position: relative;
    width: auto !important;
    font-family: "Avenir", Helvetica, Arial, sans-serif !important;
}
.advanced-search-input .advanced-input-container .advanced-input-section .advanced-input::placeholder,
.advanced-search-input .advanced-input-container .advanced-input-section .advanced-input span, 
.advanced-search-input .advanced-input-container .advanced-input-section .label::placeholder, 
.advanced-search-input .advanced-input-container .advanced-input-section .label span {
    color: black !important;
}
.advanced-search-input .advanced-input-container .advanced-input-section .advanced-input, 
.advanced-search-input .advanced-input-container .advanced-input-section .label{
  border: 1px solid #ced4da !important;
  padding-left: 0.5rem;
}
.advanced-search-input .search .options .row
{
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}
</style>