<template>
  <div id="app">
    <div class="page" v-if="getSpinner">
      <b-spinner class="spinner" :variant="'primary'" :key="'primary'"></b-spinner>
    </div>
    <div class="clearfix">
      <b-img style="max-width: 20rem;" class ="float-left ml-4 mt-2" src="./assets/TandF_logo.png" alt="Left image"></b-img>
      <div>
       <b-button pill variant="outline-danger" class= "float-right mr-4 mt-4">Logout</b-button>
      </div>
    </div>
    <div id="nav">
      <top-header></top-header>
    </div>
    <router-view />
  </div>
</template>
<script>
import TopHeader from "@/components/top-header.vue";
import { mapGetters } from "vuex";
export default {
  components: { "top-header": TopHeader },
  computed: {
    ...mapGetters(["getSpinner"])
  }
};
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 20px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.page {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 25;
  width: 100%;
  height: 100%;
}
.spinner {
  z-index: 26;
  position: relative;
  top: 50%;
}
</style>
