<template>
  <div class="about">
    <b-container>
    <upload-file></upload-file>
    </b-container>
  </div>
  
  <!-- <div class="about">
    <h1>This is an about page</h1>
  </div> -->
</template>

<script>
import UploadFile from '../components/UploadFile.vue'

  export default {
    name: "UploadFileForIntermediary",
    components: { "upload-file": UploadFile },
  }
</script>
