import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/UploadFilesForIntermediary.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "UploadFilesForIntermediary",
    component: Home
  },
  {
    path: "/FileProcessingSummary",
    name: "FileProcessingSummary",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FileProcessingSummary.vue")
  },
  {
    path: "/IntermediaryMapping",
    name: "IntermediaryMapping",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/IntermediaryMapping.vue"),
      
  },
  {
    path: "/IntermediaryMapping/ReviewFileMapping",
    name: "ReviewFileMapping",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ReviewFileMapping.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
